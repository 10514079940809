<template>
  <div>
    <transition name="fade-faster" mode="out-in">
      <select-service-substep
        v-if="displayedSubstep === 'select-service'"
        :loading="loading"
        :services="services"
        :diagnostic-service="diagnosticService"
        :selected-services="selectedServices"
        :diagnostic-appointment-feature-enabled="
          diagnosticAppointmentFeatureEnabled
        "
        @diagnostic-appt-selected="handleDiagnosticApptSelected"
        @service-selected="handleSelectService"
        @display-service-additional-notes-substep="
          $emit('display-service-additional-notes-substep')
        "
        @selected-service-confirmed="confirmSelectedService"
      />

      <diagnostic-appointment-survey
        v-else-if="displayedSubstep === 'diagnostic-appointment-survey'"
        @display-select-service-substep="handleDisplaySelectServiceSubstep"
        @header-title-change="
          (newTitle) => $emit('header-title-change', newTitle)
        "
        @display-diagnostic-additional-info-substep="
          (payload) => {
            $emit('display-diagnostic-additional-info-substep', payload);
          }
        "
      />

      <manually-describe-service-step
        key="service_additional_notes"
        :is-form-optional="true"
        v-else-if="displayedSubstep === 'service-additional-notes'"
        @info-submitted="handleServiceAdditionalNotesSubmitted"
      />

      <manually-describe-service-step
        key="diagnostic_additional_info"
        :is-form-optional="true"
        v-else-if="displayedSubstep === 'diagnostic-additional-info'"
        @info-submitted="handleDiagnosticInfoSubmitted"
      />
    </transition>
  </div>
</template>

<script>
import SelectServiceSubstep from "@/components/select-service-step/SelectServiceSubstep";
import ServiceRow from "@/components/select-service-step/ServiceRow";
import ManuallyDescribeServiceStep from "@/components/select-service-step/ManuallyDescribeServiceStep";
import DiagnosticAppointmentSurvey from "@/components/select-service-step/DiagnosticAppointmentSurvey";

import ShopServicesService from "@/services/ShopServicesService";

export default {
  name: "SelectServiceStep",
  components: {
    SelectServiceSubstep,
    ServiceRow,
    ManuallyDescribeServiceStep,
    DiagnosticAppointmentSurvey,
  },
  props: {
    accountSlug: {
      type: String,
      required: true,
    },
    displayedSubstep: {
      type: String,
      required: true,
    },
    accountDiagnosticData: {
      type: Object,
      required: true,
    },
    diagnosticAppointmentFeatureEnabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      services: [
        {
          slug: "service-1",
        },
        {
          slug: "service-2",
        },
        {
          slug: "service-3",
        },
      ],
      selectedServices: [],
      diagnosticService: {
        slug: "diagnostic-appointment",
      },
    };
  },
  mounted() {
    this.fetchServices();
  },
  methods: {
    async fetchServices() {
      // TODO: Remove this flag after service group rollout
      if (this.$posthog.getFeatureFlag("use_service_group")) {
        const response = await ShopServicesService.get.index(this.accountSlug, {
          type: "booking-services",
        });

        this.services = response.data.services.filter(service => !service.is_diagnostic);
        this.diagnosticService = response.data.services.find(service => service.is_diagnostic);
      } else {
        const response = await ShopServicesService.get.index(this.accountSlug);

        this.services = response.data.services;

        this.diagnosticService = {
          slug: "diagnostic-appointment",
          appt_duration_mins: response.data.diagnostic_duration_in_min,
          service_name:
            this.accountDiagnosticData?.title || "Describe Your Issue",
          service_description:
            this.accountDiagnosticData?.description ||
            "Includes a free inspection",
          is_diagnostic_service: true,
          free_diagnostic: response.data.free_diagnostic,
          price_from: response.data.diagnostic_price.from,
          price_to: response.data.diagnostic_price.to,
        };
      }

      this.loading = false;
    },
    handleDisplaySelectServiceSubstep() {
      this.selectedServices = [];
      this.$emit("display-select-service-substep");
    },
    handleDiagnosticInfoSubmitted(payload) {
      this.$emit("diagnostic-info-submitted", payload);
    },
    handleSelectService(service) {
      if (this.diagnosticServicSelected) this.diagnosticServicSelected = false;

      const isServiceAlreadySelected = this.selectedServices.find(
        (selectedService) => selectedService.slug === service.slug
      );

      if (isServiceAlreadySelected) {
        this.selectedServices = this.selectedServices.filter(
          (selectedService) => selectedService.slug !== service.slug
        );
        return;
      }

      this.selectedServices.push(service);
    },
    confirmSelectedService() {
      this.$emit("service-selected", { services: this.selectedServices });
    },
    handleServiceAdditionalNotesSubmitted({ description, selectedFile }) {
      this.$emit("service-selected", {
        services: this.selectedServices,
        description,
        selectedFile,
      });
    },
    handleDiagnosticApptSelected() {
      this.$emit("display-diagnostic-appointment-survey-substep", this.diagnosticService);
    },
  },
};
</script>

import apiClient from '@/client';

export default {
  get: {
    index: (shop, params = {}) => {
      return apiClient.get(`/public/accounts/${shop}/services`, { params });
    },
    shopInfo: (slug) => {
      return apiClient.get(`/public/accounts/${slug}`);
    },
    shopExtraInfo: (slug) => {
      return apiClient.get(`/public/accounts/${slug}/extra`);
    },
    availabilityData: (
      slug,
      appointmentType,
      services,
      daysAhead = 30,
      startDate = undefined,
      useServiceGroupFlag = false, // TODO: Remove this flag after service group rollout
    ) => {
      return apiClient.get(`/public/accounts/${slug}/availability`, {
        params: {
          days_ahead: daysAhead,
          appointment_type: appointmentType,
          services,
          start_date: startDate,
          group_availability: useServiceGroupFlag,
        },
      });
    },
  },
};

<template>
  <div class="welcome-back-wrapper">
    <div class="text-header-1">Welcome back {{ customerFirstName }}! 👋</div>
  </div>
</template>

<script>
export default {
  name: "WelcomeBack",
  props: {
    customerFullName: {
      type: String,
      required: true,
    },
  },
  computed: {
    customerFirstName() {
      return this.customerFullName.split(" ")[0];
    },
  },
};
</script>

<style style="scss">
.welcome-back-wrapper {
  padding: 0 16px;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
</style>
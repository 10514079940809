import apiClient from '@/client';

export default {
  get: {
    recognisedCustomerVehicles: (shop, email, phone) => {
      return apiClient.get(`/public/accounts/${shop}/customer-vehicles`, {
        params: {
          email,
          phone,
        },
      });
    },
  },
  post: {
    bookAppointment: (
      googleRecaptchaToken,
      appointmentTime,
      appointmentType,
      selectedServiceSlugs,
      selectedServiceAdditionalNotes,
      requestedServiceDescription,
      attachments,
      email,
      name,
      phone,
      address,
      recognisedCustomerSlug,
      customerRequestedRideHome,
      termsAccepted,
      year,
      make,
      model,
      recognisedVehicleExternalId,
      shop,
      licensePlate,
      deferredJobIds = [],
      uniqueUserId = null,
      extraParams = {},
      useServiceGroup = false,
    ) => {
      const formData = new FormData();

      formData.append('google_recaptcha_token', googleRecaptchaToken);

      formData.append('appointment_time', appointmentTime);
      formData.append('appointment_type', appointmentType);

      if (selectedServiceSlugs.length) {
        selectedServiceSlugs.forEach((slug, idx) => {
          formData.append(`selected_service_slugs[${idx}]`, slug);
        });
      }

      formData.append('selected_service_additional_notes', selectedServiceAdditionalNotes);

      if (requestedServiceDescription) {
        formData.append('requested_service_description', requestedServiceDescription);
      }

      if (attachments && attachments.length) {
        Array.from(attachments).forEach((attachment, idx) => {
          if (attachment && attachment != 'null') {
            formData.append(`attachments[${idx}]`, attachment);
          }
        });
      }
      // check if email is valid email
      let emailRegex = /\S+@\S+\.\S+/;
      if (emailRegex.test(email)) {
        formData.append('email', email);
      }
      formData.append('name', name);
      formData.append('phone', phone);

      if (address.address) {
        formData.append('address', address.address);
      }
      
      if (address.city) {
        formData.append('city', address.city);
      }

      if (address.zip) {
        formData.append('zip', address.zip);
      }

      if (address.state) {
        formData.append('state', address.state);
      }

      if (address.place_id) {
        formData.append('place_id', address.place_id);
      }

      if (recognisedCustomerSlug) {
        formData.append('recognised_customer_slug', recognisedCustomerSlug);
      }

      if (customerRequestedRideHome) {
        formData.append('customer_requested_ride_home', customerRequestedRideHome);
      }

      formData.append('terms_accepted', termsAccepted ? 1 : 0);

      formData.append('year', year);
      formData.append('make', make);
      formData.append('model', model);

      if (recognisedVehicleExternalId) {
        formData.append('recognised_vehicle_external_id', recognisedVehicleExternalId);
      }

      if (deferredJobIds.length) {
        deferredJobIds.forEach((id, idx) => {
          formData.append(`deferred_job_ids[${idx}]`, id);
        });
      }

      if (licensePlate) formData.append('license_plate', licensePlate);

      if (uniqueUserId) {
        formData.append('unique_user_id', uniqueUserId);
      }

      if (!shop) {
        shop = 'shopgenie';
      }

      if (Object.keys(extraParams).length) {
        Object.entries(extraParams).forEach(([key, value]) => {
          formData.append(key, value);
        });
      }

      // TODO: Remove after service group rollout
      if (useServiceGroup) {
        formData.append('use_service_group', useServiceGroup);
      }

      return apiClient.post(`/public/accounts/${shop}/repair-requests`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    abandonAppointment: (payload) => {
      return apiClient.post(`/public/appointments/abandon`, {
        ...payload,
      });
    },
  },
};

<template>
  <transition-group name="fade-fastest" mode="out-in">
    <div key="exit-survey">
      <div class="exit-survey">
        <div v-if="isShopOpen">
          <span
            @click="callShop"
            class="exit-survey-call-button secondary-btn full-width"
          >
            <span>{{ isDesktop ? accountPhone : 'Call Us' }}</span>
          </span>
        </div>
        <hr class="divider" />
        <form action="">
          <div class="form-group">
            <label class="form-label"
              >Got questions? Our team will get you an answer 👇</label
            >
            <el-input
              type="textarea"
              class="message-textarea"
              placeholder="Add your questions or concerns here"
              rows="4"
              v-model="description"
            />
          </div>

          <div class="form-group">
            <label class="form-label">Full name</label>
            <el-input placeholder="Ex: John Smith" v-model="name" />
          </div>

          <div class="form-group">
            <label class="form-label">Phone number</label>
            <el-input
              placeholder="(555) 555-5555"
              type="tel"
              v-model="phone"
              inputmode="tel"
              oninput="this.value = this.value.replace(/[^\d+]+/g, '').replace(/(\..*)\./g, '$1')"
            />
          </div>

          <transition name="fade-faster">
            <div v-if="errorMessage" class="form-group">
              <div class="text-error">
                {{ errorMessage }}
              </div>
            </div>
          </transition>

          <div class="form-group">
            <label class="terms">
              By submitting this form, you consent to receive SMS messages
              and/or emails from {{ accountName }}. To unsubscribe, follow the
              instructions provided in our communications. Msg & data rates may
              apply for SMS. Your information is secure and will not be sold to
              third parties.
            </label>
          </div>
        </form>
      </div>

      <div class="sticked-to-bottom-button-wrapper">
        <div class="content-container">
          <button
            type="button"
            class="primary-btn full-width submit-contact-info"
            :disabled="submitButtonDisabled"
            @click.prevent="submitExitSurvey"
          >
            <span>Submit</span>
          </button>
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
import CustomersService from "@/services/CustomersService";

export default {
  name: "ExitSurvey",
  props: {
    customerName: {
      type: String,
      default: "",
    },
    customerPhone: {
      type: String,
      default: "",
    },
    accountName: {
      type: String,
      default: "this shop",
    },
    accountSlug: {
      type: String,
      required: true,
    },
    isShopOpen: {
      type: Boolean,
      required: false,
      default: true,
    },
    accountPhone: {
      type: String,
      required: false,
      default: "",
    },
  },
  mounted() {
    this.$posthog.capture("Exit Survey", {
      type: "viewed",
    });
  },
  data() {
    return {
      name: this.customerName,
      phone: this.customerPhone,
      description: "",
      errorMessage: "",
      loading: false,
    };
  },
  computed: {
    submitButtonDisabled() {
      return (
        !this.name || !this.phone || this.phone.length < 10 || !this.description
      );
    },
    isDesktop() {
      const { desktop } = this.$route.query;
      if (desktop === undefined) return false;
      return !!Number(desktop);
    },
  },
  methods: {
    callShop() {
      this.$posthog.capture("Exit Survey", {
        type: "call shop",
        shop: this.accountName,
      });
      window.parent.postMessage(
        {
          name: "action.callPhone",
          payload: {
            phone: this.accountPhone,
          },
        },
        "*"
      );
    },
    async submitExitSurvey() {
      try {
        this.errorMessage = null;
        this.loading = true;
        await CustomersService.post.contact(
          this.accountSlug,
          this.name,
          this.phone,
          this.description
        );
        this.loading = false;
        this.$posthog.capture("Exit Survey", {
          type: "submitted",
        });

        window.parent.postMessage(
          {
            name: "searchgenie.closeModal",
          },
          "*"
        );
      } catch (error) {
        this.errorMessage = "Something went wrong. Please try again later.";
        this.loading = false;
        this.$posthog.capture("Exit Survey", {
          type: "submission failed",
          error: error,
        });
      }
    },
  },
};
</script>
